<template>
  <div class="row q-col-gutter-sm" :class="isMobile ? 'q-mt-sm' : ''">
    <div :class="isMobile ? 'col-12' : 'col-12 col-sm-4'">
      <div class="form-field pb-0">
        <q-select
          dense
          options-dense
          outlined
          label="Filter By"
          :value="orderFilters.selectedFilter"
          :options="orderFilters.filterOptions"
          @input="applyFilters"
        />
      </div>
    </div>
    <div :class="isMobile ? 'col-12' : 'col-12 col-sm-8'">
      <div class="form-field pb-0">
        <q-input
          outlined
          v-model="localFilterSearchBy"
          color="grey"
          label="Enter Product name or Order number here"
          @clear="inputFilters"
          clearable
        >
          <template v-slot:append>
            <q-btn
              unelevated
              no-caps
              color="secondaryOnBody"
              @click="inputFilters"
              label="Search"
              dense
              class="q-px-sm r-4"
              :disable="!localFilterSearchBy"
            />
          </template>
        </q-input>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'OrderSearch',
  data() {
    return {
      orders: {},
      orderfilter: 'Last 3 Months',
      orderfilterInput: '',
      startDate: '',
      endDate: '',
    }
  },
  computed: {
    ...mapGetters('persistedLocal', ['orderFilters']),
    ...mapGetters('order', ['filterSearchBy']),
    localFilterSearchBy: {
      get() {
        return this.filterSearchBy
      },
      set(val) {
        this.$store.commit('order/SET_FILTER_SEARCH', val)
      },
    },
    filterOptions() {
      let options = [
        'All Orders',
        'Last 30 Days',
        'Last 3 Months',
        'Last 6 Months',
      ]
      let currentYear = new Date().getFullYear()
      for (let year = currentYear; year > currentYear - 5; year--) {
        options.push(`${year}`)
      }
      return options
    },
  },
  methods: {
    calculateOrderDate() {
      let currentDate = new Date()
      let startDate, endDate
      let orderfilter = this.orderFilters.selectedFilter
      switch (orderfilter) {
        case 'All Orders':
          break
        case 'Last 30 Days':
          startDate = new Date(
            new Date().setDate(currentDate.getDate() - 30)
          ).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
          endDate = currentDate.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
          break
        case 'Last 3 Months':
          startDate = new Date(
            new Date().setDate(currentDate.getDate() - 90)
          ).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
          endDate = currentDate.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
          break
        case 'Last 6 Months':
          startDate = new Date(
            new Date().setDate(currentDate.getDate() - 180)
          ).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
          endDate = currentDate.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
          break
        default:
          startDate = new Date(orderfilter, 0, 1).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
          endDate = new Date(orderfilter, 12, 0).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
          break
      }
      this.startDate = startDate
      this.endDate = endDate
    },
    applyFilters(val) {
      this.$store.commit('persistedLocal/SET_ORDER_FILTER', val)
      this.calculateOrderDate()
      this.$emit('filterModified', {
        StartDate: this.startDate,
        EndDate: this.endDate,
        searchby: this.localFilterSearchBy,
        filterBy: this.orderfilter,
      })
    },
    inputFilters() {
      this.$store.commit('order/SET_FILTER_SEARCH', this.localFilterSearchBy)
      this.calculateOrderDate()
      this.$emit('filterModified', {
        StartDate: this.startDate,
        EndDate: this.endDate,
        searchby: this.localFilterSearchBy,
        filterBy: this.orderfilter,
      })
    },
  },
  beforeCreate() {
    let options = [
      'All Orders',
      'Last 30 Days',
      'Last 3 Months',
      'Last 6 Months',
    ]
    let currentYear = new Date().getFullYear()
    for (let year = currentYear; year > currentYear - 5; year--) {
      options.push(`${year}`)
    }
    this.$store.commit('persistedLocal/SET_ORDER_FILTER_OPTIONS', options)
  },
}
</script>

<style lang="scss"></style>
